@import "../../styles/imports.scss";

$current: $mobile;

.parking-summary-wrapper {
  .short-summary {
    display: flex;
    flex-direction: column;
    margin: vw_size(10, $current) 0;

    .total {
      margin-top: vw_size(7, $current);

      .title {
        font-size: vw_size(20, $current);
        text-decoration: underline;
      }
      .text {
        margin-right: vw_size(3, $current);
        font-size: vw_size(20, $current);
      }
    }
    .data-explaination {
      margin-top: vw_size(10, $current);
      margin-bottom: vw_size(20, $current);
      color: red;
    }
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;

  .parking-summary-wrapper {
    .short-summary {
      margin: vw_size(10, $current) 0;

      .total {
        margin-top: vw_size(7, $current);

        .title {
          font-size: vw_size(20, $current);
        }
        .text {
          margin-right: vw_size(3, $current);
          font-size: vw_size(20, $current);
        }
      }
      .data-explaination {
        margin-top: vw_size(10, $current);
        margin-bottom: vw_size(20, $current);
      }
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;

  .parking-summary-wrapper {
    margin: 0 vw_size(225, $current);
    .short-summary {
      margin: vw_size(10, $current) 0;

      .total {
        margin-top: vw_size(7, $current);

        .title {
          font-size: vw_size(20, $current);
        }
        .text {
          margin-right: vw_size(3, $current);
          font-size: vw_size(20, $current);
        }
      }
      .data-explaination {
        margin-top: vw_size(10, $current);
        margin-bottom: vw_size(20, $current);
      }
    }
  }
}

@media (min-width: $media-desktop_large) {
    $current: $desktop_max;
  
    .parking-summary-wrapper {
      margin: 0 vw_size(360, $current);
      .short-summary {
        margin: vw_size(10, $current) 0;
  
        .total {
          margin-top: vw_size(7, $current);
  
          .title {
            font-size: vw_size(20, $current);
          }
          .text {
            margin-right: vw_size(3, $current);
            font-size: vw_size(20, $current);
          }
        }
        .data-explaination {
          margin-top: vw_size(10, $current);
          margin-bottom: vw_size(20, $current);
        }
      }
    }
  }
  
@import "../../styles/imports";

.home {
  padding: vw_size(15, $mobile);
  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logout {
      font-size: vw_size(16, $mobile);
      margin-bottom: vw_size(10, $mobile);
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 100;
      color: #FFB200;
      border-radius: 36px;
      background-color: rgba(255, 178, 0, 0.08);
      padding: vw_size(16, $mobile) vw_size(50, $mobile);
      font-size: vw_size(20, $mobile);
    }
    .logo-wrapper {
      width: vw_size(235, $mobile);
      height: vw_size(140, $mobile);
    }
  }
}

@media (min-width: $media-tablet) {
  .home {
    padding: vw_size(15, $tablet);
    .options {
      .logout {
        font-size: vw_size(16, $tablet);
        margin-bottom: vw_size(10, $tablet);
      }
    }
    .main-content {

      .title {
        padding: vw_size(16, $tablet) vw_size(50, $tablet);
        font-size: vw_size(30, $tablet);
      }
      .logo-wrapper {
        width: vw_size(235, $tablet);
        height: vw_size(140, $tablet);
      }
    }
  }
}
@media (min-width: $media-desktop) {
  .home {
    padding: vw_size(15, $desktop);
    .options {
      .logout {
        font-size: vw_size(16, $desktop);
        margin-bottom: vw_size(10, $desktop);
      }
    }
    .main-content {

      .title {
        padding: vw_size(16, $desktop) vw_size(50, $desktop);
        font-size: vw_size(50, $desktop);
      }
      .logo-wrapper {
        width: vw_size(235, $desktop);
        height: vw_size(140, $desktop);
      }
    }
  }
}
@media (min-width: $media-desktop-large) {
  .home {
    padding: vw_size(15, $desktop-large);
    .options {
      .logout {
        font-size: vw_size(16, $desktop_large);
        margin-bottom: vw_size(10, $desktop_large);
      }
    }
    .main-content {

      .title {
        padding: vw_size(16, $desktop_large) vw_size(50, $desktop_large);
        font-size: vw_size(50, $desktop_large);
      }
      .logo-wrapper {
        width: vw_size(235, $desktop_large);
        height: vw_size(110, $desktop_large);
      }
    }
  }
}


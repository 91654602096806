@import '../../styles/imports';

.home {
    padding: vw_size(15,$mobile);
}
@media (min-width: $media-tablet) {
    .home {
        padding: vw_size(15,$tablet);
    }
}
@media (min-width: $media-desktop) {
    .home {
        padding: vw_size(15,$desktop);
    }
}
@media (min-width: $media-desktop-large) {
    .home {
        padding: vw_size(15,$desktop-large);
    }
}
@import '../../../styles/imports';

.burger-menu-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background: transparent;
    pointer-events: none;
    transition: background 300ms ease-out;

    &.active {
        pointer-events: all;
        background: rgba(0, 0, 0, 0.5);

        .burger-menu {
            right: 0;
        }
    }

    .burger-menu {
        position: absolute;
        top: 0;
        width: vw_size(270, $mobile);
        height: 100vh;
        overflow-y: auto;
        right: - vw_size(270,$mobile);
        transition: right 300ms ease-out;
        background: white;
        z-index: 5;
        color: #2e2e2e;

        .close-icon {
            height: vw_size(50, $mobile);
            width: fit-content;
            margin-right: auto;
            padding: vw_size(10, $mobile) vw_size(8, $mobile);

            .close-img {
                cursor: pointer;
            }

            img {
                object-fit: scale-down;
                width: 70%;
            }
        }

        .burger-menu-list {
            padding: 0;
            margin: 0;

            .burger-menu-item {
                font-size: vw_size(20, $mobile);
                line-height: 1.21;
                letter-spacing: -2.07px;
                text-align: center;
                color: #6c6c6c;
                border-top: 1px solid #ccc;
            }

            .burger-menu-link {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: vw_size(16, $mobile);
                text-align: right;
                height: vw_size(50, $mobile);
                color: #6c6c6c;

                &.active {
                    //color:#ffa600;
                }

                &:last-child {
                    //border:0;
                }
            }
        }
    }
}

@media (min-width: $media-tablet) {
    .burger-menu-wrapper {
        .burger-menu {
            max-width: 40vw;

            .close-icon {
                height: vw_size(50, $tablet);
                padding: vw_size(10, $tablet) vw_size(8, $tablet);
            }

            .burger-menu-list {
                .burger-menu-item {

                }

                .burger-menu-link {
                    display: flex;
                    align-items: center;
                    font-size: vw_size(20, $tablet);
                    text-align: right;
                    height: vw_size(50, $tablet);
                }
            }
        }
    }
}

@media (min-width: $media-desktop) {
    .burger-menu-wrapper {
        .burger-menu {
            max-width: 28.5vw;

            .close-icon {
                height: vw_size(50, $desktop);
                padding: vw_size(10, $desktop) vw_size(8, $desktop);
            }

            .burger-menu-list {
                .burger-menu-item {

                }

                .burger-menu-link {
                    display: flex;
                    align-items: center;
                    font-size: vw_size(20, $desktop);
                    text-align: right;
                    height: vw_size(50, $desktop);
                }
            }
        }
    }
}
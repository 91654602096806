@import "../../../styles/imports.scss";
$font-size: 15;
.ticker-wrapper {
  display: inline-flex;
  direction: ltr;
  .separator {
    font-size: vw_size($font-size, $mobile);
  }
}

@media (min-width: $media-tablet) {
  .ticker-wrapper {
    .separator {
      font-size: vw_size($font-size, $tablet);
    }
  }
}

@media (min-width: $media-desktop) {
  .ticker-wrapper {
    .separator {
      font-size: vw_size($font-size, $desktop);
    }
  }
}

@media (min-width: $media-desktop-large) {
  .ticker-wrapper {
    .separator {
      font-size: vw_size($font-size, $desktop-large);
    }
  }
}

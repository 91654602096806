@import "../../../styles/imports.scss";
$current: $mobile;
.multi-select-autocomplete-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .select-wrapper {
    width: 100%;
    height: vw_size(25, $current);

    &.active {
      .dropdown-menu {
        max-height: vw_size(200, $current);
        overflow: scroll;
        display: flex;
        flex-direction: column;
        border: 1px solid lightgray;
        overflow-x: hidden;
        .button-wrapper {
          display: flex;
        }
      }
      .dropdown-img {
        transform: rotate(0);
      }
    }
    &.disabled {
      pointer-events: none;
      .dropdown-menu {
        pointer-events: none;
      }
    }
    .input {
      border-bottom: 1px solid rgb(118, 118, 118);
      font-size: vw_size(18, $current);
      width: 100%;
      &::placeholder {
        color: gray;
      }
    }
    .no-caret {
      caret-color: transparent;
    }
    .dropdown-img {
      position: absolute;
      top: vw_size(5, $current);
      left: 0;
      width: vw_size(10, $current);
      transform: rotate(90deg);
      transition: all 0.2s ease-in-out;
    }
    .dropdown-menu {
      position: absolute;
      transition: all 0.3s ease-in-out;
      width: 100%;
      top: vw_size(25, $current);
      transition: max-height 200ms;
      max-height: 0;
      overflow: hidden;
      left: 0;
      background-color: white;
      padding: unset;
      margin: 0;
      .select_option {
        display: flex;
        justify-content: space-between;
        flex: 0 0 auto;
        align-items: center;
        height: vw_size(40, $current);
        font-size: vw_size(18, $current);
        padding-inline: vw_size(10, $current);
        border-bottom: 1px solid lightgray;
        cursor: pointer;
        &:last-of-type {
          border: none;
        }
        &.highlight {
          background-color: rgba(253, 181, 8, 0.5);
        }
        &.active {
        }
        .checked {
          width: vw_size(20, $current);
        }
      }
      .button-wrapper {
        position: sticky;
        bottom: 0;
        background-color: #e3e8ea;
        display: none;
        justify-content: center;
        align-items: center;
        .button {
          background-color: $yellow;
          border-radius: 5px;
          color: black;
          transition-duration: 0.4s;
          padding: vw_size(10, $current) vw_size(20, $mobile);
          font-size: vw_size(14, $current);
          &:hover {
            background-color: #ffffff;
            border: 1px solid black;
          }
        }
      }
    }
  }
  .selected-options-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: vw_size(14, $current);
    flex-wrap: wrap;
    justify-content: flex-start;
    .selected-option {
      display: flex;
      flex-direction: row;
      margin-left: vw_size(16, $current);
      &:last-child {
        margin-left: 0;
      }
      .selected-text {
        display: flex;
        align-items: center;
        font-size: vw_size(12, $current);
        line-height: vw_size(14.2, $current);
      }
      .icon-wrapper {
        margin-left: vw_size(5, $current);
        width: vw_size(9, $current);
        display: flex;
        align-items: center;
        :hover {
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .multi-select-autocomplete-wrapper {
    .select-wrapper {
      height: vw_size(25, $current);

      &.active {
        .dropdown-menu {
          max-height: vw_size(200, $current);
        }
      }
      .input {
        font-size: vw_size(18, $current);
      }
      .dropdown-img {
        top: vw_size(5, $current);
        width: vw_size(10, $current);
      }
      .dropdown-menu {
        top: vw_size(25, $current);

        .select_option {
          height: vw_size(40, $current);
          font-size: vw_size(18, $current);
          padding-inline: vw_size(10, $current);

          .checked {
            width: vw_size(20, $current);
          }
        }
        .button-wrapper {
          .button {
            padding: vw_size(10, $current) vw_size(20, $mobile);
            font-size: vw_size(14, $current);
          }
        }
      }
    }
    .selected-options-wrapper {
      margin-top: vw_size(14, $current);

      .selected-option {
        margin-left: vw_size(16, $current);

        .selected-text {
          font-size: vw_size(12, $current);
          line-height: vw_size(14.2, $current);
        }
        .icon-wrapper {
          margin-left: vw_size(5, $current);
          width: vw_size(9, $current);
        }
      }
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop;
  .multi-select-autocomplete-wrapper {
    .select-wrapper {
      height: vw_size(25, $current);

      &.active {
        .dropdown-menu {
          max-height: vw_size(200, $current);
        }
      }
      .input {
        font-size: vw_size(18, $current);
      }
      .dropdown-img {
        top: vw_size(5, $current);
        width: vw_size(10, $current);
      }
      .dropdown-menu {
        top: vw_size(25, $current);

        .select_option {
          height: vw_size(40, $current);
          font-size: vw_size(18, $current);
          padding-inline: vw_size(10, $current);

          .checked {
            width: vw_size(20, $current);
          }
        }
        .button-wrapper {
          .button {
            padding: vw_size(10, $current) vw_size(20, $current);
            font-size: vw_size(14, $current);
          }
        }
      }
    }
    .selected-options-wrapper {
      margin-top: vw_size(14, $current);

      .selected-option {
        margin-left: vw_size(16, $current);

        .selected-text {
          font-size: vw_size(12, $current);
          line-height: vw_size(14.2, $current);
        }
        .icon-wrapper {
          margin-left: vw_size(5, $current);
          width: vw_size(9, $current);
        }
      }
    }
  }
}

@media (min-width: $media-desktop_large) {
  $current: $desktop_max;
  .multi-select-autocomplete-wrapper {
    .select-wrapper {
      height: vw_size(25, $current);

      &.active {
        .dropdown-menu {
          max-height: vw_size(200, $current);
        }
      }
      .input {
        font-size: vw_size(18, $current);
      }
      .dropdown-img {
        top: vw_size(5, $current);
        width: vw_size(10, $current);
      }
      .dropdown-menu {
        top: vw_size(25, $current);

        .select_option {
          height: vw_size(40, $current);
          font-size: vw_size(18, $current);
          padding-inline: vw_size(10, $current);

          .checked {
            width: vw_size(20, $current);
          }
        }
        .button-wrapper {
          .button {
            padding: vw_size(10, $current) vw_size(20, $current);
            font-size: vw_size(14, $current);
          }
        }
      }
    }
    .selected-options-wrapper {
      margin-top: vw_size(14, $current);

      .selected-option {
        margin-left: vw_size(16, $current);

        .selected-text {
          font-size: vw_size(12, $current);
          line-height: vw_size(14.2, $current);
        }
        .icon-wrapper {
          margin-left: vw_size(5, $current);
          width: vw_size(9, $current);
        }
      }
    }
  }
}

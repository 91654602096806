@import 'styles/imports';

.documentation-wrapper {
    direction: ltr;
    padding: vw_size(15,$mobile);

    .menu-wrapper {
        display: flex;

        .dropdown {
            position: relative;
            display: inline-block;

            .dropdown-trigger {
                &.active {
                    color:orangered;
                }
            }

            .dropdown-content {
                display: none;
                position: absolute;
                background-color: #f1f1f1;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                z-index: 1;

                & > * {
                    color: black;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: block;

                    &:hover {
                        background-color: #ddd;
                    }
                }

                & > .active {
                    color:orangered;
                }
            }
        }

        .dropdown:hover {
            .dropdown-content {
                display: block;
            }
        }
    }
}
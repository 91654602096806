@import 'styles/imports';

.documentation-wrapper {
    direction: ltr;
    padding: vw_size(15,$mobile);
    font-size: vw_size(14,$mobile);
    color:#555;

    .menu-wrapper {
        margin-bottom: vw_size(20,$mobile);
    }
    .docs-category-item, .docs-item {
        padding:0 vw_size(10,$mobile);

        &:first-child {
            padding-left:0;
        }
        &:hover, &.active {
            color:$react-teal;
        }
    }
    .docs-item {
        &:hover, &.active {
            color:orangered;
        }
    }
    .component {
        background: #f9f9f9;
        padding:vw_size(10,$mobile) vw_size(5,$mobile) vw_size(20,$mobile) vw_size(10,$mobile);
        margin-bottom: vw_size(3,$mobile);

        .component-title {
            font-size: vw_size(18,$mobile);
            font-weight: bold;
            text-decoration: underline;
            padding-bottom: vw_size(5,$mobile);
        }
    }
    .import-strip {
        background: #f1f1f1;
        padding:vw_size(10,$mobile);
        margin-bottom: vw_size(3,$mobile);
    }
    .code-sample {
        background: #e9e9e9;
        padding:vw_size(10,$mobile);
        white-space: pre-line;
        margin-bottom: vw_size(3,$mobile);
    }
    .note {
        padding:vw_size(10,$mobile);
        border:1px solid grey;
        margin-bottom: vw_size(3,$mobile);

        .note-label {
            font-weight: bold;
            margin-bottom: vw_size(5,$mobile);
        }
    }
    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: vw_size(5,$mobile) vw_size(15,$mobile);
        background: $react-teal;
        color:white;
        
        &:hover {
            background: orangered;
        }
    }
}

@media (min-width: $media-tablet) {
    .documentation-wrapper {
        padding: vw_size(15,$tablet);
        font-size: vw_size(14,$tablet);

        .menu-wrapper {
            margin-bottom: vw_size(20,$tablet);
        }
        .docs-category-item, .docs-item {
            padding:0 vw_size(10,$tablet);
        }
        .docs-item {
            padding:0 vw_size(10,$tablet);
        }
        .component {
            padding:vw_size(10,$tablet) vw_size(5,$tablet) vw_size(20,$tablet) vw_size(10,$tablet);
            margin-bottom: vw_size(3,$tablet);

            .component-title {
                font-size: vw_size(18,$tablet);
                padding-bottom: vw_size(5,$tablet);
            }
        }
        .import-strip {
            padding:vw_size(10,$tablet);
            margin-bottom: vw_size(3,$tablet);
        }
        .code-sample {
            padding:vw_size(10,$tablet);
            margin-bottom: vw_size(3,$tablet);
        }
        .note {
            padding:vw_size(10,$tablet);
            margin-bottom: vw_size(3,$tablet);

            .note-label {
                margin-bottom: vw_size(5,$tablet);
            }
        }
        .button {
            padding: vw_size(5,$tablet) vw_size(15,$tablet);
        }
    }
}
@media (min-width: $media-desktop) {
    .documentation-wrapper {
        padding: vw_size(15,$desktop);
        font-size: vw_size(14,$desktop);

        .menu-wrapper {
            margin-bottom: vw_size(20,$desktop);
        }
        .docs-category-item, .docs-item {
            padding:0 vw_size(10,$desktop);
        }
        .docs-item {
            padding:0 vw_size(10,$desktop);
        }
        .component {
            padding:vw_size(10,$desktop) vw_size(5,$desktop) vw_size(20,$desktop) vw_size(10,$desktop);
            margin-bottom: vw_size(3,$desktop);

            .component-title {
                font-size: vw_size(18,$desktop);
                padding-bottom: vw_size(5,$desktop);
            }
        }
        .import-strip {
            padding:vw_size(10,$desktop);
            margin-bottom: vw_size(3,$desktop);
        }
        .code-sample {
            padding:vw_size(10,$desktop);
            margin-bottom: vw_size(3,$desktop);
        }
        .note {
            padding:vw_size(10,$desktop);

            .note-label {
                margin-bottom: vw_size(5,$desktop);
            }
        }

    }
}
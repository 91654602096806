@import '../styles/imports';

.popup {
    .backdrop {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 10000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: all 200ms ease-out;

        &.active {
            opacity: 1;
        }
        &.done {
            opacity: 1;
        }
    }
}
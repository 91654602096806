@import '../../../styles/imports.scss';


.animated-input-wrapper{
  position: relative;

  .placeholder{
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    transition: all 200ms ease-out;
    transform-origin: right;
    pointer-events: none;
    align-items: center;
    font-size: vw_size(20, $mobile);

    .icon {
      width: vw_size(16, $mobile);
      margin: 0 0 vw_size(5, $mobile) vw_size(5, $mobile);
    }

    &.animated{
      transform: translate(0, - vw_size(20, $mobile)) scale(0.9);
      font-size: vw_size(17, $mobile);
      color: black;
    }
  }


  .input{
    width: 100%;
    direction: rtl;

    &:focus + .placeholder{
      transform: translate(0, - vw_size(20, $mobile)) scale(0.9);
    }

  }
}

@media (min-width: $media-tablet) {
  .animated-input-wrapper{

    .placeholder{
      font-size: vw_size(30, $tablet);

      .icon {
        width: vw_size(18, $tablet);
        margin: 0 0 vw_size(5, $tablet) vw_size(5, $tablet);
      }

      &.animated{
        transform: translate(0, - vw_size(20, $tablet)) scale(0.9);
        font-size: vw_size(25.5, $tablet);
      }
    }

    .input{

      &:focus + .placeholder{
        transform: translate(0, - vw_size(20, $tablet)) scale(0.9);
      }
    }
  }
}

@media (min-width: $media_desktop) {
  .animated-input-wrapper{

    .placeholder{
      font-size: vw_size(20, $desktop);

      .icon {
        width: vw_size(16, $desktop);
        margin: 0 0 vw_size(5, $desktop) vw_size(5, $desktop);
      }

      &.animated{
        transform: translate(0, - vw_size(20, $desktop)) scale(0.9);
        font-size: vw_size(17, $desktop);
      }
    }

    .input{

      &:focus + .placeholder{
        transform: translate(0, - vw_size(20, $desktop)) scale(0.9);
      }
    }
  }
}

@media (min-width: $media_desktop_large) {
  .animated-input-wrapper{

    .placeholder{
      font-size: vw_size(20, $desktop_large);

      .icon {
        width: vw_size(16, $desktop_large);
        margin: 0 0 vw_size(5, $desktop_large) vw_size(5, $desktop_large);
      }

      &.animated{
        transform: translate(0, - vw_size(20, $desktop_large)) scale(0.9);
        font-size: vw_size(17, $desktop_large);
      }
    }

    .input{

      &:focus + .placeholder{
        transform: translate(0, - vw_size(20, $desktop_large)) scale(0.9);
      }
    }
  }
}
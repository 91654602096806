@import '../../styles/imports';

.footer-wrapper {
    width: 100%;
    background-color: #505050;
    height: 5vw;
    color: #fff;
    padding: vw_size(20, $mobile);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: vw_size(10, $mobile);
    border-top: 1px solid #333;
    z-index: 0;

    .footer-title {
        opacity: .6;
    }
    .rights-wrapper {
        display: flex;
        align-items: center;
        width: vw_size(105, $mobile);

        img {
            width: vw_size(55, $mobile);
        }

        span {
            //display: block;
            white-space: nowrap;
            opacity: .6;
            margin-right: vw_size(8, $mobile);
        }
    }
}

@media (min-width: $media-tablet) {
    .footer-wrapper {
        height: vw_size(55, $tablet);
        font-size: vw_size(13, $tablet);
        padding: 0 vw_size(15, $tablet) 0 vw_size(15, $tablet);

        .rights-wrapper {
            width: vw_size(220, $tablet);

            span {
                margin-right: vw_size(12, $tablet);
            }
            img {
                width: vw_size(100, $tablet);
            }
        }
    }
}

@media (min-width: $media-desktop) {
    .footer-wrapper {

    }
}

@media (min-width: $media-desktop-large) {
    .footer-wrapper {
        height: 3.125vw;
        font-size: .72917vw;
        padding: 0 13.80208vw 0 1.04167vw;

        .rights-wrapper {
            width: 10.41667vw;

            span {
                margin-right: 0.52083vw;
            }
        }
    }
}
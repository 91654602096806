@import "../../styles/imports";

.header-wrapper {
  height: vw_size(50, $mobile);
  border-bottom: 1px solid lightgray;

  .header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 vw_size(15, $mobile);
    height: vw_size(50, $mobile);
    z-index: 1000;
    background-color: #ffffff;
    color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  .logo-header {
    width: vw_size(40, $mobile);
  }
  .burger-icon {
    filter: invert(100%);
    width: vw_size(15, $mobile);
    cursor: pointer;
  }
}

@media (min-width: $media-tablet) {
  .header-wrapper {
    height: vw_size(50, $tablet);

    .header {
      height: vw_size(50, $tablet);
      padding: 0 vw_size(15, $tablet);
    }
    .logo-header {
      width: vw_size(40, $tablet);
    }

    .burger-icon {
      max-width: 2.8vw;
      max-height: 2.8vw;
    }
  }
}

@media (min-width: $media-desktop) {
  .header-wrapper {
    height: vw_size(60, $desktop);

    .header {
      height: vw_size(60, $desktop);
      padding: 0 vw_size(15, $desktop);
      .logo-header {
        width: vw_size(40, $desktop);
      }
    }

    .burger-icon {
      max-width: 1.3vw;
      max-height: 1.3vw;
    }
  }
}

@media (min-width: $media-desktop-large) {
  .header-wrapper {
    height: vw_size(70, $desktop-large);

    .header {
      height: vw_size(70, $desktop-large);
      padding: 0 vw_size(15, $desktop-large);
      .logo-header {
        width: vw_size(40, $desktop-large);
      }
    }
  }
}

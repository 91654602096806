@import '../../styles/imports';

.login-form {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 95%;
    margin: vw_size(10, $mobile) auto;
}

.login-header {
    display: flex;
    background-color: #f0f0f0;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;

    .login-logo {
        object-fit: scale-down;
        width: vw_size(40, $mobile);
    }
    .login-title {
        text-align: center;
        margin-left: vw_size(25, $mobile);
    }
}

.login-email-input, .login-password-input {
    margin-block: vw_size(25,$mobile);
    //width: 80vw;
}

.login-email-input, .login-password-input, .login-btn {
    width: 80vw;
}

.login-email-input {
    margin-top: vw_size(50, $mobile);
}

.login-btn {
    padding: vw_size(10,$mobile);
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
    cursor: pointer;
    color: black;
    background-color: #fdb508;
    border: 1px solid #fdb508;
    border-radius: 5px;
    font-size: vw_size(20, $mobile);
}

.login-btn:hover {
    background-color: #ffffff;
    border: 1px solid black;
}

.login-checkbox{
    padding: 10px;
}

.error {
    border: 1px solid red;
}

@media (min-width: $media-tablet) {
    .login-form {
        //padding: vw_size(15,$tablet);
        width: vw_size(500, $tablet);
    }
    .login-email-input, .login-password-input {
        margin-block: vw_size(20,$tablet);
    }
    .login-email-input, .login-password-input, .login-btn {
        width: 45vw;
    }
    .login-email-input {
        margin-top: vw_size(50, $tablet);
    }
    .login-btn {
        padding: vw_size(10,$tablet);
        font-size: vw_size(20, $tablet);
    }
    .login-header {
        .login-logo {
            width: vw_size(50, $tablet);
        }
        .login-title {
            margin-left: vw_size(85, $tablet);
        }
    }
}
@media (min-width: $media-desktop) {
    .login-form {
        //padding: vw_size(15,$desktop);
        width: vw_size(400, $desktop);
    }
    .login-email-input, .login-password-input {
        margin-block: vw_size(20,$desktop);
    }
    .login-email-input, .login-password-input, .login-btn {
        width: 25vw;
    }
    .login-email-input {
        margin-top: vw_size(50, $desktop);
    }
    .login-btn {
        padding: vw_size(10,$desktop);
        font-size: vw_size(20, $desktop);
    }
    .login-header {
        .login-logo {
            width: vw_size(50, $desktop);
        }
        .login-title {
            margin-left: vw_size(65, $desktop);
        }
    }
}
@media (min-width: $media-desktop-large) {
    .login-form {
        //padding: vw_size(15,$desktop-large);
        width: vw_size(300, $desktop);
    }
    .login-email-input, .login-password-input, .login-btn {
        width: 15vw;
    }
    .login-email-input {
        margin-top: vw_size(50, $desktop);
    }
    .login-btn {
        padding: vw_size(8,$desktop);
        font-size: vw_size(15, $desktop);
    }
    .login-header {
        .login-logo {
            width: vw_size(40, $desktop);
        }
        .login-title {
            margin-left: vw_size(40, $desktop);
        }
    }
}
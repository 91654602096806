@import "../../../../styles/imports.scss";
$font-size: 15;
.tickerCell {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  .tickerCellValue {
    font-size: vw_size($font-size, $mobile);
  }
}

@media (min-width: $media-tablet) {
  .tickerCell {
    .tickerCellValue {
      font-size: vw_size($font-size, $tablet);
    }
  }
}

@media (min-width: $media-desktop) {
  .tickerCell {
    .tickerCellValue {
      font-size: vw_size($font-size, $desktop);
    }
  }
}

@media (min-width: $media-desktop-large) {
  .tickerCell {
    .tickerCellValue {
      font-size: vw_size($font-size, $desktop-large);
    }
  }
}

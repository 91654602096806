@import 'styles/imports';
.tool-wrapper {
    &.with-preview {
        display: flex;
        flex-wrap: wrap;
    }
}
@media (min-width: $media-tablet) {
    .tool-wrapper {
        &.with-preview {
            .left, .right {
                flex:0 0 50%;
            }
            .right {
                padding:0 20px;
            }
        }
    }
}
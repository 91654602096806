@import "../../styles/imports";

.home {
  padding: vw_size(15, $mobile);
  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logout {
      font-size: vw_size(16, $mobile);
      margin-bottom: vw_size(10, $mobile);
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .section-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
    gap: 10px;

    .section-title {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .inputs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border: 3px solid $yellow;
    padding: 12px;
    flex-wrap: wrap;
    width: fit-content;
    margin: auto;
    border-radius: 10px;
    .inputs-wrapper {
      padding: 0 20px;
      .field {
        margin: 10px 0;

        .input-label {
          color: black;
          margin: 5px 0 0;
          font-size: vw_size(11.9, $mobile);
        }
      }

      .single-field {
        width: 100%;
      }
      .choose-parking-garage {
        margin: vw_size(20, $mobile) 0px 10px;
      }
    }

    .send-btn {
      color: black;
      border-radius: 5px;
      padding: 20px;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.5s ease;
      background-color: $yellow;

      &:hover {
        color: black;
        border: 1px solid black;
        cursor: pointer;
      }
    }
  }

  .api-message {
    text-align: center;
    padding: 20px;
  }
}

@media (min-width: $media-tablet) {
  .home {
    padding: vw_size(15, $tablet);
    .options {
      .logout {
        font-size: vw_size(16, $tablet);
        margin-bottom: vw_size(10, $tablet);
      }
    }

    .inputs-container {
      .inputs-wrapper {
        display: flex;
        flex-direction: row;
        .field {
          margin-right: vw_size(10, $tablet);
          width: 33%;
        }
        .single-field {
          width: 100%;
        }
        .choose-parking-garage {
          margin: vw_size(44, $tablet) vw_size(10, $tablet) 0;
        }
      }
    }
  }
}
@media (min-width: $media-desktop) {
  .home {
    padding: vw_size(15, $desktop);
    .options {
      .logout {
        font-size: vw_size(16, $desktop);
        margin-bottom: vw_size(10, $desktop);
      }
    }
    .inputs-container {
      .inputs-wrapper {
        .field {
          margin-right: vw_size(10, $desktop);

          .input-label {
            font-size: vw_size(14, $desktop);
          }
        }
        .choose-parking-garage {
          margin: vw_size(27, $desktop) vw_size(10, $desktop) 0;
        }
      }
    }
  }
}
@media (min-width: $media-desktop-large) {
  .home {
    padding: vw_size(15, $desktop-large);
    .options {
      .logout {
        font-size: vw_size(16, $desktop_large);
        margin-bottom: vw_size(10, $desktop_large);
      }
    }
    .inputs-container {
      .inputs-wrapper {
        .field {
          margin-right: vw_size(10, $desktop-large);
        }
        .choose-parking-garage {
          margin: vw_size(29, $desktop-large) vw_size(10, $desktop-large) 0;
        }
      }
    }
  }
}


@import "../../styles/imports";

$fontSizeMedium: 14px;
$fontSizeSmall: 12px;

@media print {
  .header-wrapper {
    display: none;
  }
  .grecaptcha-badge {
    display: none !important;
  }
  .home {
    .options, .inputs-container {
      display: none;
    }

    .parking-summary-wrapper {
      .short-summary {
        .total {
            margin: 0;
            padding: 0;
          .title, .text {
            font-size: $fontSizeMedium;
          }
        }

        .data-explaination {
          font-size: $fontSizeSmall;
          margin-bottom: 0;
        }
      }
    }

    .parking-lot-table {

      .table-header {
        height: 30px;
        .header-block {
          font-size: $fontSizeSmall;
          text-align: center;
          height: 30px;
        }
      }
      .table-body {
        .row {
          height: 30px;
          .body-block {
            font-size: $fontSizeSmall;
            height: 30px;
          }
        }
      }
    }
  }
  .parking-lot-title {
    font-size: $fontSizeMedium;
  }
}

@media (orientation: portrait) {
  .parking-lot-title {
    font-size: $fontSizeMedium;
  }
  .home {
    .parking-lot-table {

      .table-header {
        height: 45px;
        :nth-child(1) {
          width: 300px;
        }
        .header-block {
          height: 45px;
        }
      }
      .table-body {
        .row {
          height: 45px;
          :nth-child(1) {
            width: 300px;
          }
          .body-block {
            font-size: $fontSizeSmall;
            height: 45px;
          }
        }
      }
    }
  }
}

@import "../../../styles/imports.scss";

.date-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    padding-top: 2px;
  }
  .date-title {
    color: black;
    font-size: vw_size(11.9, $mobile);
  }
  .react-datepicker__input-container{
    input{
      width: 100%;

    }
  }
}

@media (min-width: $media-desktop) {
  .date-container {
    .date-title {
      font-size: vw_size(14, $desktop);
    }
  }
}

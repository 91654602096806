@import '../../../../styles/imports';

.loader_wrapper {
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background: rgba(0,0,0,0.3);
    width: 100vw;
    height: 100vh;
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
        width: 80px;
        height: 80px;
    }
    .loader {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $loader-color;
        border-color: white transparent white transparent;
        animation: loader 500ms linear infinite;
    }
    @keyframes loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
@import-normalize;
@import './imports';

html {
    background: $background-color;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: border-box;
}
body {
    margin:0;
    padding:0;
    direction: rtl;
    background: $background-color;
}
#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.App {
    flex: 1;
}
input {
    border-width: 0 0 1px 0;
    border-color: 1px solid $grey;
}
input:focus  {
    border-color: inherit;
}
textarea {
    display: block;
    outline: none;
    resize: none;
    overflow:auto;
    border: 1px solid lightgray;
    border-radius: 4px;
    width:100%;
}
input:focus, textarea:focus {
    outline: none;
}
button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
button:focus, button:hover, :focus {
    outline: none;
}
a, a:focus, a:active {
    color: inherit;
    text-decoration: none;
}
ul {
    list-style: none;
}
img {
    display: block;
    width: 100%;
}

/* Fix webkit autofill background - Change the white to any color */
input:-webkit-autofill {
    box-shadow: 0 0 0 30px #ffffff inset;
}

@import './device-state';
@import './styles';
@import "../../../styles/imports.scss";

$current: $mobile;
.parking-lot-data {
  &:not(&:first-child) {
    margin: vw_size(10, $current) 0;
  }
  .parking-lot-title {
    margin: vw_size(10, $current) 0;
  }
  .parking-lot-table {
    border: 1px solid black;
    .blocks-wrapper {
      display: flex;
      justify-content: space-between;
      .block {
        width: 33.66666667%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: vw_size(10, $current) 0;
        &.sum-entries, &.sum-exits, &.sum-force-exits {
          border-right: 1px solid black;
        }
        &.sum-refunds {
          border-right: 1px solid black;
          border-left: 1px solid black;
        }
        &.avg-minutes {
          border-right: 1px solid black;
        }
        &.sum-discounts {
          border-left: 1px solid black; 
        }
        &.body-block {
          border-top: 1px solid black;
        }
      }
    }
    .table-header {
      .header-block {
        text-align: center;
        background-color: #fdb508;
      }
    }
    .table-body {
      display: flex;
      flex-direction: column;
      text-align: center;
      .row {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .parking-lot-data {
    .parking-lot-title {
      margin: vw_size(10, $current) 0;
    }
    .parking-lot-table {
      .blocks-wrapper {
        .block {
          padding: vw_size(10, $current) 0;
        }
      }
    }
  }
}

@media (min-width: $media-desktop) {
  $current: $desktop_max;
  .parking-lot-data {
    .parking-lot-title {
      margin: vw_size(10, $current) 0;
    }
    .parking-lot-table {
      .blocks-wrapper {
        .block {
          padding: vw_size(10, $current) 0;
        }
      }
    }
  }
}

